import { createGlobalStyle } from 'styled-components';

export const TransactionFieldConfigStyles = createGlobalStyle`
  // add class name here
  &.cap,
  &.insuranceInformation,
  &.bankFile,
  &.numberOfAVArticle,
  &.vehicleModalName, 
  &.vehicleModalVinNumber,
  &.ucr,
  &.dateShippedOnBoard,
  &.vehicleModalTyreSize {
    display: none !important;
  }

  // when adding the class name, always include [class^="FieldFrame-module_fieldContainer"] to disable the actual input
  .dummy-disabled [class^="FieldFrame-module_fieldContainer"] {
    background-color: #FAFBFC !important;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
